import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  grid-area: preTitle;

  margin-bottom: ${selectTheme('spacingBottom.3xl')};

  text-transform: uppercase;
`

export { Typography }
