import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Cta } from 'components/Cta'

const StyledCta = styled(Cta)(
  ({ color, theme }) => css`
    white-space: nowrap;
    text-decoration: none;
    color: ${selectTheme(`colors.${color}`)({ theme })};

    width: 100%;
    height: fit-content;

    grid-area: button;

    transition: all 0.3s ease-in-out;

    svg {
      display: none;
    }

    ${media.down('3xl')} {
      margin-bottom: ${selectTheme('spacingBottom.16xl')({ theme })};
    }
  `,
)

export { StyledCta }
