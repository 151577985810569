import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  grid-area: title;

  margin-bottom: ${selectTheme('spacingBottom.6xl')};
`

export { Typography }
