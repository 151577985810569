import { array, object, oneOf, string } from 'prop-types'

import { renderTemplate } from 'utils/renderTemplate'

import { selectTheme } from './base.styles'
import Template01 from './Template01'
import Template02 from './Template02'

const propTypes = {
  backgroundColor: oneOf(['white', 'dark']),
  buttonCta: array,
  buttonCtaLabel: object,
  buttonCtaStyle: string,
  cta: array,
  horizontalAlignment: string,
  imageDesktop: object,
  imageMobile: object,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2']),
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2']),
  variant: oneOf(['Template01', 'Template02']),
}

const defaultProps = {
  variant: 'Template01',
  preTitleHeadingTag: 'h1',
  titleHeadingTag: 'h2',
  backgroundColor: 'white',
}

const ELEMENT_VARIANTS = {
  Template01,
  Template02,
}

const GenericImage = ({
  backgroundColor,
  buttonCtaLabel,
  buttonCtaStyle,
  cta,
  ctaButton: buttonCta,
  imageDesktop,
  imageMobile,
  preTitleHeadingTag,
  titleHeadingTag,
  ...others
}) => {
  const titleAs = titleHeadingTag ?? defaultProps.titleHeadingTag
  const preTitleAs = preTitleHeadingTag ?? defaultProps.preTitleHeadingTag
  const sectionTheme = selectTheme(backgroundColor)
  const hasImage = !!imageDesktop?.fluid && !!imageMobile?.fluid
  cta = cta?.length && cta[0] ? cta[0] : {}
  buttonCta = buttonCta?.length && buttonCta[0] ? buttonCta[0] : cta

  const buttonCtaProps = {
    ...buttonCta,
    url: buttonCta.actionHref || buttonCta.url,
    type: buttonCta.actionType || buttonCta.type,
    label: buttonCta.label || buttonCtaLabel,
    style: buttonCtaStyle,
  }

  return renderTemplate(
    {
      titleAs,
      preTitleAs,
      sectionTheme,
      hasImage,
      buttonCta: buttonCtaProps,
      imageDesktop,
      imageMobile,
      ...others,
    },
    ELEMENT_VARIANTS,
  )
}

GenericImage.propTypes = propTypes
GenericImage.defaultProps = defaultProps

export default GenericImage
