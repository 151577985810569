import React from 'react'
import { func, object, oneOf, string } from 'prop-types'

import { ButtonPrimaryTextOnly, ButtonSecondary } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { StyledCta } from './styles'

const propTypes = {
  buttonCta: object,
  color: string,
  label: string,
  onClick: func,
  style: oneOf(['primary', 'secondary']),
}

const defaultProps = {
  buttonCta: {},

  onClick: () => {},
  style: 'primary',
  color: string,
}

const ButtonCta = ({ buttonCta, color, label, onClick, style, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!label) return null

  const ButtonElement =
    style === 'secondary' ? ButtonSecondary : ButtonPrimaryTextOnly

  const analyticEvent = {
    label: `${label}`,
    pageInteraction: buttonCta?.type !== 'link',
  }

  const handleOnClick = () => {
    triggerEvent(analyticEvent)
    onClick()
  }

  return (
    <StyledCta
      element={ButtonElement}
      width="flexible"
      size="large"
      onClick={handleOnClick}
      color={color}
      {...buttonCta}
      {...others}
    >
      {label}
    </StyledCta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
