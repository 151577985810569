import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const containerStyles = ({ theme }) => ({
  paddingOnMobile: css`
    ${media.down('3xl')} {
      padding-left: ${selectTheme('spacingRight.3xl')({ theme })};
      padding-right: ${selectTheme('spacingRight.3xl')({ theme })};
    }
  `,
})

const Container = styled.div(
  ({ paddingOnMobile, theme }) => css`
    grid-area: info;

    width: 100%;

    ${media.up('3xl')} {
      display: grid;
      grid-auto-rows: min-content;
      grid-gap: 0px 0px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        '${'preTitle '.repeat(4).trim()}'
        '${'title '.repeat(4).trim()}'
        '${'description '.repeat(4).trim()}'
        '${'button '.repeat(3).trim()} .';
    }

    ${media.up('5xl')} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: min-content;
      grid-gap: 0px 0px;
      grid-template-areas:
        '${'preTitle '.repeat(5).trim()}'
        '${'title '.repeat(5).trim()}'
        '${'description '.repeat(5).trim()}'
        '${'button '.repeat(3).trim()} . .';
    }

    ${containerStyles({ theme })[paddingOnMobile ? 'paddingOnMobile' : '']}
  `,
)

export { Container }
