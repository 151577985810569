import React from 'react'
import { bool, object, shape, string } from 'prop-types'

import ButtonCta from '../components/ButtonCta'
import Description from '../components/Description'
import Image from '../components/Image'
import InfoContainer from '../components/InfoContainer'
import PreTitle from '../components/PreTitle'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  bgColor: string,
  buttonCta: object,
  description: string,
  hasImage: bool,
  horizontalAlignment: string,
  imageDesktop: object,
  imageMobile: object,
  preTitle: string,
  preTitleAs: string,
  sectionTheme: shape({
    [string]: string,
  }),
  title: string,
  titleAs: string,
}

const Template01 = ({
  buttonCta,
  description,
  hasImage,
  imageDesktop,
  imageMobile,
  preTitle,
  preTitleAs,
  sectionTheme,
  title,
  titleAs,
  ...others
}) => {
  return (
    <Wrapper
      imageVariant="full"
      data-testid="section-highlightimage"
      bgColor={sectionTheme?.section.backgroundColor}
      {...others}
    >
      <InfoContainer>
        <PreTitle color={sectionTheme?.preTitle?.color} as={preTitleAs}>
          {preTitle}
        </PreTitle>
        <Title color={sectionTheme?.title?.color} as={titleAs}>
          {title}
        </Title>
        <Description color={sectionTheme?.description?.color}>
          {description}
        </Description>

        <ButtonCta
          {...buttonCta}
          color={sectionTheme?.cta[buttonCta?.style]?.color}
        />
      </InfoContainer>
      {hasImage && (
        <Image
          variant="full"
          imageDesktop={imageDesktop}
          imageMobile={imageMobile}
        />
      )}
    </Wrapper>
  )
}

Template01.propTypes = propTypes

export default Template01
