import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.60',
}

const PreTitle = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography
      variant={{ _: 'exceptionSmBold', '5xl': 'exceptionMdBold' }}
      {...others}
    >
      {children}
    </Typography>
  )
}

PreTitle.propTypes = propTypes
PreTitle.defaultProps = defaultProps

export default PreTitle
