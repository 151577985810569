import { css, styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)(
  ({ theme }) => css`
    grid-area: description;

    margin-bottom: ${selectTheme('spacingBottom.8xl')({ theme })};
    ${media.up('5xl')} {
      margin-bottom: ${selectTheme('spacingBottom.12xl')({ theme })};
    }
  `,
)

export { Typography }
