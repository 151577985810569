import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const imageVariantStyle = {
  small: css`
    width: 100%;
    height: 202px;

    ${media.up('3xl')} {
      filter: drop-shadow(0px 28px 64px rgba(31, 45, 39, 0.16));

      width: 330px;
      height: 330px;
    }

    ${media.up('4xl')} {
      width: 424px;
      height: 424px;
    }

    ${media.up('5xl')} {
      width: 392px;
      height: 392px;
    }
  `,
  full: css`
    width: 100%;
    height: 320px;

    margin: ${selectTheme('spacingY.5xl')};

    filter: drop-shadow(0px 28px 64px rgba(31, 45, 39, 0.16));

    ${media.up('3xl')} {
      width: 325px;
      height: 338px;

      margin: 0;
    }

    ${media.up('4xl')} {
      width: 100%;
      height: 534px;

      margin: 0;
    }

    ${media.up('5xl')} {
      width: 100%;
      height: 624px;

      margin: 0;
    }
  `,
}

const ImageElement = styled(LazyImage)(
  ({ variant }) => css`
    grid-area: image;

    object-fit: contain;
    ${imageVariantStyle[variant]}
  `,
)

export { ImageElement }
