import React from 'react'
import { bool, node, object, oneOf, string } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  bgColor: string,
  children: node,
  currentSectionRef: object,
  horizontalAlignment: oneOf(['left', 'right']),
  imageVariant: string,
  noPaddingOnMobile: bool,
}

const defaultProps = {
  bgColor: 'neutral.0',
  imageVariant: 'full',
  noPaddingOnMobile: false,
  horizontalAlignment: 'left',
}

const Wrapper = ({
  children,
  currentSectionRef,
  horizontalAlignment: horizontalAlignmentProp,
  imageVariant,
  noPaddingOnMobile,
  ...others
}) => {
  const horizontalAlignment =
    horizontalAlignmentProp ?? defaultProps.horizontalAlignment
  return (
    <Section {...others} ref={currentSectionRef}>
      <Container
        imageVariant={imageVariant}
        noPaddingOnMobile={noPaddingOnMobile}
      >
        <Grid
          horizontalAlignment={horizontalAlignment}
          imageVariant={imageVariant}
        >
          {children}
        </Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
