const selectTheme = sectionTheme =>
  ({
    dark: {
      cta: {
        secondary: {
          color: 'primary.40',
        },
        primary: {
          color: 'neutral.90',
        },
      },
      description: {
        color: 'neutral.0',
      },
      preTitle: { color: 'neutral.0' },
      section: { backgroundColor: 'neutral.90' },
      title: { color: 'neutral.0' },
    },
    white: {
      cta: {
        secondary: {
          color: 'primary.80',
        },
        primary: {
          color: 'neutral.90',
        },
      },
      description: {
        color: 'neutral.60',
      },
      section: { backgroundColor: 'neutral.0' },
      title: { color: 'neutral.90' },
    },
  }[sectionTheme || 'white'])

export { selectTheme }
